import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor() { }

  public isMobile(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipod|android.*mobile|blackberry|opera mini|windows\sce|palm|smartphone|iemobile/.test(userAgent);
  }

  public isDesktop(): boolean {
    return !this.isMobile() && !this.isTablet();
  }

  public isTablet(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const screenWidth = window.innerWidth;

    // Define breakpoints for tablets
    const tabletMinWidth = 600;
    const tabletMaxWidth = 1024;

    // Check for user agent and screen size
    const isTabletSize = screenWidth >= tabletMinWidth && screenWidth <= tabletMaxWidth;
    const isTabletUA = /ipad|android(?!.*mobile)|tablet|kindle|playbook|silk/.test(userAgent);

    return isTabletSize || isTabletUA;
  }
}
