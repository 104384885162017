import { Injectable, WritableSignal, computed, signal } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  sidebarVisible: WritableSignal<boolean> = signal(false);

  public loadFromServer: WritableSignal<boolean> = signal(false);
  constructor() {}

 

  setSidebarVisability(value: boolean) {
    this.sidebarVisible.set(value);
  }
}
