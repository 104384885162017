import { Injectable } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { Toast } from "primeng/toast";
import { AppInjector } from "src/app/app.component";
import { ToastConfig } from "./data/snackbar.types";
import {Message} from 'primeng/api/message';


@Injectable({
	providedIn: 'root',
	deps: [MessageService, ConfirmationService],
})
export class ToastService {
	MessageAndSubToast: Toast;
	MessageToast: Toast;

	/* -------------------------------------------------------------------------- */
	public static get instance(): ToastService {
		return AppInjector.get(ToastService);
	}

	constructor(private messageService: MessageService) {}

	/* -------------------------------------------------------------------------- */
	public open<T>(config: ToastConfig<T>) {
		config.message = config.message;
		if (config.subMessage) {
			config.subMessage = config.subMessage;
		}
		this.messageService.clear();

		let icon = config.icon 
		if (icon) {
			icon += ` fa-${config.iconSize || 'xl'}`;
		}

		const toastComponent: Toast = config.subMessage ? this.MessageAndSubToast : this.MessageToast;

		const zIndex = config.zIndex || 100000;
		toastComponent.baseZIndex = zIndex;

		const toastMessageConfig: Message = {
			key: config.subMessage ? 'MessageAndSubToast' : 'MessageToast',
			summary: config.message,
			detail: config.subMessage,
			life: config.durationMS,
			severity: config.type.toLowerCase(),
			icon: icon,
			closeIcon: config.closeIcon,
			styleClass: `rounded-md font-dc ${config.styleClass}`,
			contentStyleClass: `gap-2 p-3 ${config.contentStyleClass}`,
			sticky: config.sticky || false,
			closable: config.closable || true,
		};
		toastComponent.position = config.position || 'bottom-center';
		if (config.action) {
			toastComponent.onClose.subscribe(() => {
				config.action?.action(config);
			});
		}
		this.messageService.add(toastMessageConfig);
	}
}
