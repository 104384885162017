
import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone, WritableSignal, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserRoles } from '../components/clients copy/data/user.types';
import { CompanyModel, ICompany } from '../components/signup/data/create-company.interface';
import { GlobalApi } from '../core/global.url';
import { UserModel } from '../models/user.model';
@Injectable({
	providedIn: 'root',
})
export class AuthService {
	public currentUser$: WritableSignal<UserModel> = signal(null);
	private _currentCompany$: WritableSignal<CompanyModel> = signal(null);
	constructor(private router: Router, public ngZone: NgZone, private http: HttpClient) {}

	/* -------------------------------------------------------------------------- */
	/*                                 CurrentUser                                */
	/* -------------------------------------------------------------------------- */
	public setUserData(user: UserModel) {
		this.currentUser$.set(user);
	}

	public currentUser(): WritableSignal<UserModel> {
		if (this.currentUser$()) {
			return this.currentUser$;
		} else {
			const user: UserModel = JSON.parse(localStorage.getItem('user')!);
			if (user) this.currentUser$.set(user);
		}
		return this.currentUser$;
	}

	public isAdminUser(): boolean {
		return this.getUserData().role === UserRoles.Admin;
	}
	public getUserRole(): UserRoles {
		return this.getUserData().role;
	}

	/* -------------------------------------------------------------------------- */
	/*                              Current company:                              */
	/* -------------------------------------------------------------------------- */

	public currentCompany(): WritableSignal<CompanyModel> {
		if (this._currentCompany$()) {
			return this._currentCompany$;
		} else {
			const company: ICompany = JSON.parse(localStorage.getItem('company')!);
			if (company) this._currentCompany$.set(new CompanyModel(company));
		}
		return this._currentCompany$;
	}

	public getUserData(): UserModel {
		if (this.currentUser$()) {
			return this.currentUser$();
		} else {
			const user = JSON.parse(localStorage.getItem('user')!);
			return new UserModel(user);
		}
	}

	public setCompanyData(company: CompanyModel) {
		this._currentCompany$.set(company);
	}

	public getCompanyData(): CompanyModel {
		if (this._currentCompany$()) {
			return this._currentCompany$();
		} else {
			const company: ICompany = JSON.parse(localStorage.getItem('company')!);
			return new CompanyModel(company);
		}
	}

	/* -------------------------------------------------------------------------- */
	/*                                   Signup                                   */
	/* -------------------------------------------------------------------------- */




	/* -------------------------------------------------------------------------- */
	/*                                   Logout:                                  */
	/* -------------------------------------------------------------------------- */

	public logout() {
		localStorage.removeItem('user');
		localStorage.removeItem('company');
		localStorage.removeItem('token');
		window.location.reload();
	}

	//get Authenticated user from Local Storage
	getAuthLocal() {
		const token = localStorage.getItem('user');
		const user = JSON.parse(token as string);
		return user;
	}

	//Check wither User Is looged in or not
	get isLoggedIn(): boolean {
		const user: UserModel = JSON.parse(localStorage.getItem('user'));
		return user && user?.id !== null ? true : false;
	}


	/* -------------------------------------------------------------------------- */
	/*                                Google signIn                               */
	/* -------------------------------------------------------------------------- */

	public signInWithGoogle(idToken:string):Observable<any> {
		if(!idToken) return of(null); 
		return this.http.post(`${GlobalApi.url}/auth/google-login`, {idToken});	
	}

	
}
