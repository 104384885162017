import { HttpClient } from '@angular/common/http';
import { Injectable, WritableSignal, computed, signal } from '@angular/core';
import { GetContractResponseModel } from '../../create-contract/data/contact.types';
import { Observable, catchError, map, of, retry, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';
import { IRequestSubmitData, ISignContract } from '../sign/data/contract-sign.interface';
import { ClientModel, IClient } from '../../create-contract/step-components/select-clients/data/client.model';
import { GlobalApi } from 'src/app/core/global.url';
import { ToastService } from '../../global/snackbar/toast.service';

@Injectable({
  providedIn: 'root'
})
export class SignService {

  public tokenId: WritableSignal<string> = signal('');
  public contractId: WritableSignal<string> = signal('');
  public exclusiveContractId: WritableSignal<string | null> = signal(null);
  public contractsData: WritableSignal<GetContractResponseModel[]> = signal([]);
  public currentContract: WritableSignal<GetContractResponseModel> = signal(new GetContractResponseModel());



  private readonly baseUrl = `${GlobalApi.url}`;

  constructor(private http: HttpClient, private toastService: ToastService) { }

  getContractById(id: string):Observable<GetContractResponseModel> {
    return this.http.get<GetContractResponseModel>(`${GlobalApi.url}/contract/${id}`).pipe(
      catchError((err) => {
        this.toastService.open({type:'error', message:'לא מצאנו הסכם מתאים'});
        throw err;
      })
    )
  }

  getContractByToken(token: string): Observable<any> { // Replace 'any' with your contract type
    return this.http.get(`${this.baseUrl}/sign/${token}`);
  }

  getContractByOriginalId(id: string):Observable<GetContractResponseModel[]>{
    if(!id) return of([]);
    return this.http.get<GetContractResponseModel[]>(`${GlobalApi.url}/contract/groupContractId/${id}`)
  }

  getContractHtml(language: 'he' | 'en', id: string): Observable<string> {
    const url = `${this.baseUrl}/sign/render/${id}?language=${language}`;
    return this.http.get<string>(url, { responseType: 'text' as 'json' })
      .pipe(
        retry(1), 
        catchError(this.handleError) 
      );
  }

  getSignFromDeviceContractHtml(language: 'he' | 'en', id: string, mode: 'sign' | 'preview' = 'sign'): Observable<string> {
    const url = `${this.baseUrl}/contract/render/${id}?language=${language}&mode=${mode}`;
    return this.http.get<string>(url, { responseType: 'text' as 'json' })
      .pipe(
        retry(1), 
        catchError(this.handleError) 
      );
  }

  private handleError(error: any) {
    // Customize this method to handle the error properly
    console.error('An error occurred:', error);
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  getClientContractById(id: string):Observable<ClientModel> {
    return this.http.get<IClient[]>(`${GlobalApi.url}/contract/clients/${id}`).pipe(
      map((clients) => {
        if(clients.length > 0){
          return new ClientModel(clients[0]);
        }
        return new ClientModel();
      }),
      catchError((err) => {
        this.toastService.open({type:'error', message:'לא מצאנו פרטי לקוח'});
        throw err;
      })
    )
  }

  getHebrewContract():Observable<ISignContract> {
     return this.http.get<ISignContract>('/assets/json-files/hebrew-contract.json')
  }

  getEnglishContract():Observable<ISignContract> {
    return this.http.get<ISignContract>('assets/json-files/english-contract.json')
  }

  generatePdf(requestData: IRequestSubmitData): Observable<Blob> {
    const body = requestData;
    return this.http.post(this.baseUrl+'/pdf/generate-pdf', body, { responseType: 'blob' });
  }
  submitContract(signFromDevice: boolean,contractData: IRequestSubmitData, signature: string): Observable<boolean> {
    const body = {
      token: this.tokenId(),
       contractData, 
       signature 
      };
      let url = '';
      if(signFromDevice){
        url = `${this.baseUrl}/contract/submit`;
      }else{
        url = `${this.baseUrl}/sign/submit`;
      }
    return this.http.post<{message: string}>(url, body).pipe(
      map((res) => {
        if(res.message === 'success'){
          return true;
        }else{
          return false;
        }        
      })
    )
  }

}
