import * as dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { AssetModel } from "src/app/components/new-asset/data/asset.model";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Jerusalem');

export class clientAssetsViewedModel {
    assetId: string;
    viewedAt: string;
    asset?: AssetModel;

    constructor(init?: Partial<clientAssetsViewedModel | IFullAssetViewed >) {
        Object.assign(this, init);
        const tzDate = dayjs(init.viewedAt).tz("Asia/Jerusalem");
        this.viewedAt = tzDate.format('YYYY-MM-DD HH:mm');
        if(init.asset){
            this.asset = init.asset;
        }
    }



}

export interface IFullAssetViewed {
    assetId: string;
    viewedAt: string;
    asset: AssetModel;
}