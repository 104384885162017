<p-toast />



<div [style.paddingRight]="getMainContentPadding()">
	@defer (when authService.isLoggedIn && !isPublicUrl){
	<header class="header">
		<app-header></app-header>
	</header>
}
	<main class="content" [ngStyle]="{'margin-top': isPublicUrl || !authService.isLoggedIn ? '0' : '60px' }">
        <router-outlet #outlet="outlet"></router-outlet>
    </main>
</div>

<!-- ----------------------------------------- Global Toast Container ----------------------------------------- -->
<!--Generic-->
<p-toast
	#MessageAndSubToast
	key="MessageAndSubToast"
	[baseZIndex]="10000"
></p-toast>

<!--Only Message-->
<p-toast
	#MessageToast
	key="MessageToast"
	[baseZIndex]="10000"
>
	<ng-template
		let-message
		pTemplate="message"
	>
		<div class="flex items-center gap-4">
			<i [class]="message?.icon"></i>
			<div class=" text-lg font-bold">{{ message.summary }}</div>
		</div>
	</ng-template>
</p-toast>





