import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable, of} from "rxjs";
import {inject} from "@angular/core";
import { AuthService } from "../services/auth.service";

export const AuthGuardActivation = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {

  const authService: AuthService = inject(AuthService);

  if (authService.isLoggedIn) {
    
    return of(true);
  } else {
    const router = inject(Router);
    router.navigate(["login"]);
    return of(false);

  }
};
