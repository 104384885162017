export interface ICreateCompanyRequest {
    name: string;
    logo: string;
    address: string;
    phone: string;
    email: string;
}

export interface ICompany {
    name: string;
    logo: string;
    address: string;
    phone: string;
    email: string;
    licenseNumber: string;
    custom_neighborhoods: string[];
    slug: string;
    _id: string;
    __v: number;
}

export interface IUpdateCompanyRequest {
    id: string;
    name: string;
    logo: string;
    address: string;
    phone: string;
    email: string;
    licenseNumber: string;
    custom_neighborhoods: string[];
}

export class CompanyModel{
    id: string;
    name: string;
    logo: string;
    address: string;
    phone: string;
    email: string;
    licenseNumber: string;
    custom_neighborhoods: string[];
    slug: string;

    constructor(company: ICompany){
        if(!company){
            return;
        }
        this.id = company._id;
        this.name = company.name;
        this.logo = company.logo;
        this.address = company.address;
        this.phone = company.phone;
        this.email = company.email;
        this.licenseNumber = company.licenseNumber;
        this.custom_neighborhoods = company.custom_neighborhoods;
        this.slug = company.slug;
    }
}